export default {
  "title": "Serveis",
  "placeholder-search": "Cercar servei",
  "modal-request-service": {
    "button": "Sol·licitar servei",
    "title": "Estàs a punt de ser redirigit a un lloc web extern",
    "text": "Estàs a punt de ser redirigit al lloc web del proveïdor del servei. Vols continuar?",
    "button-go": "Anar al lloc"
  },
  "card-item": {
    "free": "Gratuït"
  },
  "detail-page": {
    "accessibility": "Accesible",
    "offered-in": "Ofertat en",
    "requeriments": {
      "title": "Requisits"
    },
    "options": {
      "title": "Opcions"
    }
  },
  "confort": {
    "title": "Confort",
    "text-search-found": "Serveis trobats en confort {number}",
    "list-page": {},
    "detail-page": {}
  },
  "transport": {
    "title": "Transport",
    "text-search-found": "Serveis trobats en transport {number}",
    "list-page": {},
    "detail-page": {}
  },
  "activity": {
    "title": "Activitats",
    "text-search-found": "Serveis trobats en activitats",
    "list-page": {},
    "detail-page": {}
  }
}
