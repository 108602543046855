export default {
  "title": "Dienstleistungen",
  "placeholder-search": "Suchdienst",
  "modal-request-service": {
    "button": "Dienst anfordern",
    "title": "Sie werden auf eine externe Website weitergeleitet",
    "text": "Sie werden auf die Website des Dienstanbieters weitergeleitet. Möchten Sie fortfahren?",
    "button-go": "Zur Website"
  },
    "card-item": {
      "free": "Kostenlos"
    },
    "detail-page": {
      "accessibility": "Zugänglich",
      "offered-in": "Angebot in",
      "requeriments": {
        "title": "Anforderungen"
      },
      "options": {
        "title": "Optionen"
      }
    },
    "confort": {
      "title": "Komfort",
      "text-search-found": "Gefundene Dienstleistungen im Komfort {number}",
      "list-page": {},
      "detail-page": {}
    },
    "transport": {
      "title": "Transport",
      "text-search-found": "Gefundene Dienstleistungen im Transport {number}",
      "list-page": {},
      "detail-page": {}
    },
    "activity": {
      "title": "Aktivitäten",
      "text-search-found": "Gefundene dienstleistungen in aktivitäten",
      "list-page": {},
      "detail-page": {}
    }
  }