export default  {
  "title": "Servizi",
  "placeholder-search": "Servizio di ricerca",
  "modal-request-service": {
    "button": "Richiedi servizio",
    "title": "Stai per essere reindirizzato a un sito web esterno",
    "text": "Stai per essere reindirizzato al sito web del fornitore di servizi. Vuoi continuare?",
    "button-go": "Vai al sito"
  },
    "card-item": {
      "free": "Gratis"
    },
    "detail-page": {
      "accessibility": "Accessibile",
      "offered-in": "Offerto in",
      "requeriments": {
        "title": "Requisiti"
      },
      "options": {
        "title": "Opzioni"
      }
    },
    "confort": {
      "title": "Confort",
      "text-search-found": "Servizi trovati nel comfort {number}",
      "list-page": {},
      "detail-page": {}
    },
    "transport": {
      "title": "Trasporto",
      "text-search-found": "Servizi trovati nel trasporto {number}",
      "list-page": {},
      "detail-page": {}
    },
    "activity": {
      "title": "Attività",
      "text-search-found": "Servizi trovati in attività",
      "list-page": {},
      "detail-page": {}
    }
  }