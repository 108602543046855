export default {
  "title": "Servicios",
  "placeholder-search": "Buscar servicio",
  "modal-request-service": {
    "button": "Solicitar servicio",
    "title": "Estás por ser redirigido a un sitio web externo",
    "text": "Estás a punto de ser redirigido al sitio web del proveedor del servicio. ¿Deseas continuar?",
    "button-go": "Ir al sitio"
  },
  "card-item": {
    "free": "Gratis"
  },
  "detail-page": {
    "accessibility": "Accesible",
    "offered-in": "Ofrecido en",
    "requeriments": {
      "title": "Requisitos"
    },
    "options": {
      "title": "Opciones"
    }
  },
  "confort": {
    "title": "Confort",
    "text-search-found": "Servicios encontrados en confort {number}",
    "list-page": {},
    "detail-page": {}
  },
  "transport": {
    "title": "Transporte",
    "text-search-found": "Servicios encontrados en transporte {number}",
    "list-page": {},
    "detail-page": {}
  },
  "activity": {
    "title": "Actividades",
    "text-search-found": "Servicios encontrados en actividades",
    "list-page": {},
    "detail-page": {}
  }
}