export default {
  "title": "Zerbitzuak",
  "placeholder-search": "Bilatu zerbitzua",
  "modal-request-service": {
    "button": "Eskatu zerbitzua",
    "title": "Kanpoko webgune batera bideratuko zara",
    "text": "Zerbitzuaren hornitzailearen webgunera bideratuko zara. Jarraitu nahi duzu?",
    "button-go": "Joan gunera"
  },
  "card-item": {
    "free": "Doan"
  },
  "detail-page": {
    "accessibility": "Erosotasuna",
    "offered-in": "Erosotasunaren eskubideetan",
    "requeriments": {
      "title": "Erosotasunaren eskubideetan"
    },
    "options": {
      "title": "Erosotasunaren eskubideetan"
    }
  },
  "confort": {
    "title": "Erosotasuna",
    "text-search-found": "Zerbitzuak aurkitu dira erosotasunean {number}",
    "list-page": {},
    "detail-page": {}
  },
  "transport": {
    "title": "Garraioa",
    "text-search-found": "Zerbitzuak aurkitu dira garraioan {number}",
    "list-page": {},
    "detail-page": {}
  },
  "activity": {
    "title": "Jarduerak",
    "text-search-found": "Zerbitzuak aurkitu dira jardueretan",
    "list-page": {},
    "detail-page": {}
  }
}
